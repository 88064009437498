export default [
  {
    key: 'imageId',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'outlet',
    rules: '',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'regionCode',
    label: 'field.region',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'agentCode',
    label: 'field.agent',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'outletCode',
    label: 'field.outletCode',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'ownerName',
    label: 'field.ownerName',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'nameKm',
    label: 'field.outletNameKm',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'nameEn',
    label: 'field.outletNameEn',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'addressKm',
    label: 'field.addressKm',
    type: 'textarea',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'addressEn',
    label: 'field.addressEn',
    type: 'textarea',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    type: 'tel',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'outletSegmentId',
    label: 'field.segment',
    type: 'async-single-selection',
    repository: 'outletSegment',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'latitude',
    label: 'field.latitude',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'longitude',
    label: 'field.longitude',
    type: 'text',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'channel',
    label: 'field.channel',
    type: 'single-selection',
    options: [
      { label: 'field.onPremise', value: 1 },
      { label: 'field.offPremise', value: 2 },
    ],
    translatable: true,
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesExecutive',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    disabled: true,
    hideOptional: true,
  },
  {
    key: 'callPlanConfigId',
    label: 'field.callPlanDate',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'callPlanConfig',
    selectionKey: 'id',
    selectionLabel: 'code',
    limit: 100,
  },
  {
    hide: true,
    skip: true,
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
    disabled: true,
  },
]
